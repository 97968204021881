import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import SkeletonLoader from "tiny-skeleton-loader-react"
import { motion, useAnimation } from "framer-motion"

import { navigate } from "gatsby"
import Context from "../../context"
import config from "../../../config"
import ContentWrapper from "src/styles/ContentWrapper"
import Underlining from "src/styles/Underlining"
import { lightTheme, darkTheme } from "../../styles/Theme"

const { shownArticles } = config

const StyledSection = motion.custom(styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
`)

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
    .section-title {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        padding-right: 0;
        padding-left: 0;
      }
      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        text-align: center;
      }
    }
    .articles {
      display: flex;
      justify-content: space-between;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      margin: -2rem 0 0 0;
      padding: 0 2rem;
      &::-webkit-scrollbar {
        display: none;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        padding: 0;
        overflow: visible;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        justify-content: center;
      }
      /* Show scrollbar if desktop and wrapper width > viewport width */
      @media (hover: hover) {
        &::-webkit-scrollbar {
          display: block;
          -webkit-appearance: none;
        }

        &::-webkit-scrollbar:horizontal {
          height: 0.8rem;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          border: 0.2rem solid white;
          background-color: rgba(0, 0, 0, 0.5);
        }

        &::-webkit-scrollbar-track {
          background-color: #fff;
          border-radius: 8px;
        }
      }
      a {
        z-index: 1;
      }
    }
    .card {
      width: 16.25rem;
      height: 12rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      margin: 2rem 1rem;
      box-shadow: 0 5px 15px ${({ theme }) => theme.colors.boxShadow};
      border-radius: ${({ theme }) => theme.borderRadius};
      background: ${({ theme }) => theme.colors.card};
      transition: box-shadow 0.3s ease-out;
      &:hover {
        box-shadow: 0 5px 15px ${({ theme }) => theme.colors.boxShadowHover};
      }
      &:hover ${Underlining} {
        box-shadow: inset 0 -1rem 0 ${({ theme }) => theme.colors.secondary};
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        margin: 2rem 2.5rem 2rem 0;
      }
      .category {
        color: ${({ theme }) => theme.colors.primary};
        text-transform: uppercase;
        letter-spacing: +1px;
        font-weight: 700;
      }
      .title {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
      }
      .date {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.subtext};
        letter-spacing: +0.5px;
      }
    }
  }
`

const Articles = ({ content }) => {
  // shownArticles is set in config.js, due to the rss feed loader
  // it is currently limited to max 3
  const MAX_ARTICLES = shownArticles

  const { isIntroDone, darkMode } = useContext(Context).state
  const [articles, setArticles] = useState()
  const articlesControls = useAnimation()

  // Load and display articles after the splashScreen sequence is done
  useEffect(() => {
    const loadArticles = async () => {
      if (isIntroDone) {
        await articlesControls.start({ opacity: 1, y: 0, transition: { delay: 1 } })
        // MediumRssFeed is set in config.js

        const newArticles = content.slice(0, MAX_ARTICLES)
        setArticles(newArticles.map(item => item.node.frontmatter))
      }
    }
    loadArticles()
  }, [isIntroDone, articlesControls, MAX_ARTICLES])

  return (
    <StyledSection id="articles" initial={{ opacity: 0, y: 20 }} animate={articlesControls}>
      <StyledContentWrapper>
        <h3 className="section-title">Latest from my blog</h3>
        <div className="articles">
          {articles
            ? articles.map(item => (
                <a href={`/articles/${item.slug}`} title={item.title} id={item.slug} key={item.slug}>
                  <div className="card">
                    <span className="category">{item.title}</span>
                    <h4 className="title">{item.summary}</h4>
                    <span className="date">{item.pubDate}</span>
                  </div>
                </a>
              ))
            : [...Array(MAX_ARTICLES)].map((i, key) => (
                <div className="card" key={key}>
                  <SkeletonLoader
                    height="1.5rem"
                    style={{ marginBottom: ".5rem" }}
                    background={darkMode ? darkTheme.colors.tertiary : lightTheme.colors.tertiary}
                  />
                  <SkeletonLoader
                    background={darkMode ? darkTheme.colors.tertiary : lightTheme.colors.tertiary}
                    height="4rem"
                  />
                  <SkeletonLoader
                    background={darkMode ? darkTheme.colors.tertiary : lightTheme.colors.tertiary}
                    height=".75rem"
                    width="50%"
                    style={{ marginTop: ".5rem" }}
                  />
                </div>
              ))}
        </div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

export default Articles
