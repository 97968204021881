import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion, useAnimation } from "framer-motion"

import Context from "../../context/"
import ContentWrapper from "../../styles/ContentWrapper"
import Underlining from "../../styles/Underlining"
import Social from "../social"
import SplashScreen from "../splashScreen"
import Theme from "../../styles/Theme"

const FlexContentWrapper = styled(ContentWrapper)`
  display: flex;
`

const StyledSection = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: row;
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
  min-height: 60vh;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "center")};
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }
`

const StyledImg = styled(Img)`
  border-radius: 0.7em;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 600px;
  margin-left: auto;
  margin-bottom: auto;
  margin-top: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 300px;
    height: 300px;
    margin: auto auto 0 auto;
    border-radius: 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 250px;
    height: 250px;
  }
`

const PictureWrapper = styled(FlexContentWrapper)`
  && {
    width: 100%;
    min-height: 55vh;
    flex-direction: column;
    padding: 0;
    margin: auto 20px auto auto;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      min-height: 10vh;
      margin: auto auto 0 auto;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      margin-top: 20px;
    }
  }
`

const StyledContentWrapper = styled(FlexContentWrapper)`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: ${({ height }) => (height ? height : "100%")};
    min-height: 50vh;
    margin: auto auto auto 0px;
    padding: 3.5em .greetings {
      display: flex;
      justify-content: flex-start;
    }

    .title {
      margin-bottom: 2.5rem;
      margin-top: 0;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-bottom: 0;
      }
    }

    .subtitle {
      margin-top: -0.5rem;
    }

    .description {
      font-size: 1.125rem;
      margin-bottom: 3rem;

      a {
        color: ${({ theme }) => theme.colors.primary};
      }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 0;

      .social-content,
      .content-text {
        margin: 20px auto auto 0px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
      }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      margin: 0;
    }
  }
`

const AnimatedUnderlining = motion.custom(Underlining)

const Hero = ({ content }) => {
  const { frontmatter, body } = content[0].node
  const { isIntroDone } = useContext(Context).state

  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()
  const eControls = useAnimation()
  const sControls = useAnimation()
  const uControls = useAnimation()

  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      if (isIntroDone) {
        eControls.start({
          rotate: [0, -10, 12, -10, 9, 0, 0, 0, 0, 0, 0],
          transition: { duration: 2.5, loop: 3, repeatDelay: 1 },
        })
        await gControls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.4 },
        })
        await sControls.start({
          opacity: 1,
          x: 0,
        })
        // Animate underlining to hover state
        await uControls.start({
          boxShadow: `inset 0 -2rem 0 ${Theme.colors.secondary}`,
          transition: { delay: 0.4, ease: "circOut" },
        })
      }
    }
    pageLoadSequence()
  }, [isIntroDone, eControls, gControls, sControls, uControls])

  return (
    <StyledSection id="hero">
      {!isIntroDone && <SplashScreen />}

      <PictureWrapper>
        <StyledImg fluid={frontmatter.picture.childImageSharp.fluid} />
      </PictureWrapper>
      <StyledContentWrapper className="center" height="auto">
        <motion.div className="content-text" initial={{ opacity: 0, y: 20 }} animate={gControls}>
          <h1 className="title">
            <div className="greetings">
              {frontmatter.greetings}
              {/* <motion.div animate={eControls} style={{ originX: 0.7, originY: 0.7 }}> */}
              {/*   <Img className="emoji" fluid={frontmatter.icon.childImageSharp.fluid} /> */}
              {/* </motion.div> */}
            </div>
            {frontmatter.title}
          </h1>
          <h2 className="subtitle">
            {frontmatter.subtitlePrefix} {/* Hover state color can be set in useEffect hook */}
            <AnimatedUnderlining animate={uControls} color="tertiary" big>
              {frontmatter.subtitle}
            </AnimatedUnderlining>
          </h2>
          <div className="description">
            <MDXRenderer>{body}</MDXRenderer>
          </div>

          <motion.div className="social-content" initial={{ opacity: 0, x: 20 }} animate={sControls}>
            <Social fontSize=".95rem" padding="0.5rem 1.6rem" width="auto" withIcon={true} />
          </motion.div>
        </motion.div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Hero.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Hero
