import React from "react"

import { DiGithubAlt } from "react-icons/di"
import { RiLinkedinFill } from "react-icons/ri"
import { AiOutlineTwitter } from "react-icons/ai"
import IconExternal from "./external"

// Utility function to grab Icons by name
const Icon = ({ name, color }) => {
  switch (name.toLowerCase()) {
    case "linkedin":
      return <RiLinkedinFill />
    case "github":
      return <DiGithubAlt />
    case "twitter":
      return <AiOutlineTwitter />
    case "external":
      return <IconExternal color={color} />
    default:
      return null
  }
}

export default Icon
