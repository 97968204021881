import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import MainLayout from "src/components/MainLayout"
import SEO from "src/components/seo"
import Hero from "src/components/sections/Hero"
import Articles from "../components/sections/articles"
// import About from "../components/sections/about"
// import Interests from "../components/sections/interests"
// import Projects from "../components/sections/projects"
// import Contact from "../components/sections/contact"

const IndexPage = ({ data }) => {
  const { seoTitle, useSeoTitleSuffix } = data.index.edges[0].node.frontmatter
  const withSuffix = useSeoTitleSuffix === "true"

  return (
    <MainLayout splashScreen={false}>
      <SEO title={withSuffix ? `${seoTitle}` : `${seoTitle}`} />
      <Hero content={data.hero.edges} />
      <Articles content={data.articles.edges} />
      {/* <About content={data.about.edges} /> */}
      {/* <Interests content={data.interests.edges} /> */}
      {/* <Projects content={data.projects.edges} /> */}
      {/* <Contact content={data.contact.edges} /> */}
    </MainLayout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/index/index/" } }) {
      edges {
        node {
          frontmatter {
            seoTitle
            useSeoTitleSuffix
          }
        }
      }
    }
    articles: allMdx(filter: { fileAbsolutePath: { regex: "/index/articles/" } }) {
      edges {
        node {
          frontmatter {
            title
            summary
            slug
            categories
            pubDate
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/index/hero/" } }) {
      edges {
        node {
          body
          frontmatter {
            greetings
            title
            subtitlePrefix
            picture {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 800, quality: 90, grayscale: true) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    about: allMdx(filter: { fileAbsolutePath: { regex: "/index/about/" } }) {
      edges {
        node {
          body
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    interests: allMdx(filter: { fileAbsolutePath: { regex: "/index/interests/" } }) {
      edges {
        node {
          exports {
            shownItems
            interests {
              name
              icon {
                childImageSharp {
                  fixed(width: 20, height: 20, quality: 90) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    projects: allMdx(
      filter: { fileAbsolutePath: { regex: "/index/projects/" }, frontmatter: { visible: { eq: "true" } } }
      sort: { fields: [frontmatter___position], order: ASC }
    ) {
      edges {
        node {
          body
          frontmatter {
            title
            category
            emoji
            external
            github
            screenshot {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tags
            position
            buttonVisible
            buttonUrl
            buttonText
          }
        }
      }
    }
    contact: allMdx(filter: { fileAbsolutePath: { regex: "/index/contact/" } }) {
      edges {
        node {
          body
          frontmatter {
            title
            name
            email
            profileImage {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
