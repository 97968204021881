import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {IconContext} from "react-icons"

import links from "src/links"
import Icon from "./icons"

const {socialMedia} = links

const StyledSocialWrapper = styled.div`
  display: grid;
  /* Calculate columns, depending on how many profiles there are */
  grid-template-columns: repeat(${({itemCount}) => itemCount + 1}, auto);
  justify-content: start;
  justify-items: start;

  margin-left: -2.5rem;
  margin-right: -2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;


  a {
    margin-right: 0.5rem;
    margin-bottom: 0.75rem;
    @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
      margin-right: 3rem;
    }
  }
  @media (max-width: ${({theme}) => theme.breakpoints.md}) {
    justify-content: center;
  }
`

const StyledSocialProfile = styled.a`
  width: ${({width}) => (width ? width : "auto")};
  height: auto;
  line-height: 1em;
  background: ${({theme}) => theme.colors.background};
  background: linear-gradient(
    to right,
    ${({theme}) => theme.colors.primary} 50%,
    ${({theme}) => theme.colors.background} 50%
  );
  background-size: 205% 100%;
  background-position: right bottom;
  border-radius: ${({theme}) => theme.borderRadius};
  border: 0.125rem solid ${({theme}) => theme.colors.primary};
  padding: ${({padding}) => (padding ? padding : ".3rem 1.25rem")};
  transition: all 0.1s ease-out;
  font-size: ${({fontSize}) => (fontSize ? fontSize : "1rem")};
  font-weight: 500;
  color: ${({theme}) => theme.colors.primary};
  &:hover {
    background-position: left bottom;
    color: #ffffff;
  }
  &:hover svg {
    /* Change svg color to white */
    filter: brightness(0) invert(1);
  }
`

const Social = ({width, padding, fontSize, fontWeight, withIcon}) => (
  <StyledSocialWrapper itemCount={socialMedia.length}>
    {socialMedia.map(({name, url}, key) => {
      return (
        <StyledSocialProfile
          key={key}
          href={url}
          target="_blank"
          rel="nofollow noopener noreferrer"
          aria-label={name}
          width={width}
          padding={padding}
          fontSize={fontSize}
          fontWeight={fontWeight}
        >
          <IconContext.Provider value={{size: "2.7em"}}>
            {withIcon ? <Icon name={name} /> : null}
          </IconContext.Provider>
        </StyledSocialProfile>
      )
    })}
  </StyledSocialWrapper>
)

Social.propTypes = {
  width: PropTypes.string,
  padding: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  withIcon: PropTypes.bool,
}

export default Social
